<template>
    <div class="">

        <span class="price-start"
            v-html="getPriceText"></span>

        <span class="more-price-info"> 
        5 Nächte *<br/>
        <!--(Jede weitere Nacht: ab {{getMinRentText}}) <br/>
      	kürzere Zeiträume auf Anfrage-->
        </span>

    </div>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'minrent',
    	props: {
    		pricelist: Array,
    		minrent: Number
    	},
    	data: function() {
    		return {
    			totalStars: 5
    		};
    	},
    	computed: {
    		getPriceText: function() {
    			var priceText = '';
    			var minrentWeek = 0;
    			var priceCleaning = 0;
    			var priceService = 0;
    			var total = 0;

    			console.log('Pricelist::', this.pricelist);

    			if (this.minrent && this.pricelist) {
    				minrentWeek = this.minrent * 5;
    				//priceText += 'minrent:' + minrentWeek;


    				for (var i = 0; i < this.pricelist.length; i++) {
    					if (this.pricelist[i].type == 'FINALCLEANING') {
    						priceCleaning = this.pricelist[i].value;
    						//priceText += ' cleaning:' + this.pricelist[i].value;
    					}
    					/*if (this.pricelist[i].service === 38897) {
    						priceService = this.pricelist[i].value;
    						//	priceText += ' Service 38897:' + this.pricelist[i].value;
    					} else if (this.pricelist[i].service === 37867) {
    						//	priceText += ' Service 37867:' + this.pricelist[i].value;
    						priceService = this.pricelist[i].value;
    					} else if (this.pricelist[i].service === 34891) {
    						//	priceText += ' Service 34891:' + this.pricelist[i].value;
    						priceService = this.pricelist[i].value;
    					}*/

    					total = minrentWeek + priceCleaning + priceService;
    				}
    				priceText += ' ab <span class="discount-price h3"><strong>';
    				priceText += Number(total / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " €";
    				priceText += '</span><strong>'

    			}
    			return priceText;
    		},
    		getMinRentText: function() {
    			var minRentText = '';

    			if (this.minrent) {
    				minRentText += Number(this.minrent / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " €";
    			}

    			return minRentText;
    		}
    	}
    };
</script>
<style scoped>
    .pricehelp {
    	font-size: 13px;
    	color: #666666;

    }
</style>